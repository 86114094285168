



























import { Component, Vue } from 'vue-property-decorator'
import { inject } from 'inversify-props'
import {
  List,
  InterviewsClient,
  MinimalInterviewDto,
  InterviewUsage
} from 'ritem-common'

@Component({ components: { List } })
export default class OnboardingOverview extends Vue {
  usages = [InterviewUsage.General, InterviewUsage.B2B, InterviewUsage.B2C]
  InterviewUsage = InterviewUsage
  @inject()
  interviewsClient: InterviewsClient

  fields: any[] = [
    {
      key: 'name',
      label: 'Interview',
      sortable: true
    }
  ]
  toDetail(item: MinimalInterviewDto) {
    this.$router.push({
      name: 'onboarding-detail',
      params: { id: item.slugName }
    })
  }
}
